import React from "react";
import "./styles.scss";
import CustomLink from "../CustomLink";
import CustomImage from "../Image";

export const FooterTemplate = ({ data }) => {
    const { logoImage, socialLinks, footerLogos, pageLinks } = data;

    return (
        <nav className="footer">
            <div className="footer-container  container">
                <div className="footer-top">
                    <div className="footer-about">
                        <h4 className="footer-aboutTitle">
                            <CustomImage
                                gatsbyImage={logoImage.image.childImageSharp} alt={logoImage.imageAlt} src={logoImage.image}
                                otherProps={{ loading: "eager", className: "footer-aboutTitleImg" }} />
                        </h4>
                        <p className="footer-aboutDescription">{logoImage.tagline}</p>
                        <div className="footer-address">
                            {logoImage.street}<br />
                            {logoImage.district}<br />
                            {logoImage.country}
                        </div>
                    </div>
                    <div className="footer-page-links">
                        {pageLinks.map((pageLink, idx) => {
                            return (
                                <CustomLink key={idx} linkType={pageLink.linkType} linkURL={pageLink.linkURL}>
                                    {pageLink.label}
                                </CustomLink>
                            );
                        })}
                    </div>
                    {socialLinks.length > 0 && (
                        <ul className="footer-socialMenu">
                            {socialLinks.map(socialLink => (
                                <li key={socialLink.linkURL} className="footer-socialMenuItem">
                                    <a
                                        className="footer-socialLink"
                                        href={socialLink.linkURL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <CustomImage gatsbyImage={socialLink.image.childImageSharp} src={socialLink.image} alt={socialLink.imageAlt}
                                            otherProps={{ loading: "eager", className: "footer-socialLinkIcon" }} />
                                        {socialLink.label}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className="footer-bottom">
                    <div className="footer-flag">
                        {footerLogos && footerLogos.map((footerLogo, idx) => (
                            <a key={idx} target="_blank" rel="noopener noreferrer" href={footerLogo.linkURL}>
                                <CustomImage src={footerLogo.image.publicURL} alt={footerLogo.imageAlt} />
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </nav>
    );
};

const Footer = props => {
    if (!props.data) {
        return null;
    }
    const data = props.data.edges[0].node.frontmatter;
    return <FooterTemplate data={data} />;
};

export { Footer };
