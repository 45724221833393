import React from "react";
import { slide as MobileMenu } from 'react-burger-menu';
import "./styles.scss";
import CustomLink from "../CustomLink";
import CustomImage from "../Image";

export const NavbarTemplate = ({ data }) => (
    <>
        <nav className="navbar">
            <div className="container navbar-container">
                {data.logo ?
                    (
                        <div className="navbar-logo">
                            <a href="/"><CustomImage src={data.logo.image.publicURL || data.logo.image} alt={data.logo.imageAlt} /></a>
                        </div>
                    ) : null}
                {data.menuItems.length > 0 && (
                    <ul className="navbar-menu">
                        {data.menuItems.map(menuItem => (
                            <li key={menuItem.linkURL} className="navbar-menuItem">
                                <CustomLink
                                    linkType={menuItem.linkType}
                                    linkURL={menuItem.linkURL}
                                    className="navbar-menuLink"
                                >
                                    {menuItem.label}
                                </CustomLink>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </nav>
        <div className="mobile-nav">
            <MobileMenu>
                {data.menuItems.map(menuItem => (
                    <CustomLink
                        className="navbar-mobile-link"
                        key={menuItem.linkURL}
                        linkType={menuItem.linkType}
                        linkURL={menuItem.linkURL}
                    >
                        {menuItem.label}
                    </CustomLink>
                ))}
                <CustomLink
                    className="navbar-mobile-link"
                    linkType="internal"
                    linkURL="/kontakt"
                >
                    Kontakt
            </CustomLink>
                <CustomLink
                    className="navbar-mobile-link"
                    linkType="internal"
                    linkURL="/impressum"
                >
                    Impressum
            </CustomLink>
                <CustomLink
                    className="navbar-mobile-link"
                    linkType="internal"
                    linkURL="/datenschutz"
                >
                    Datenschutz
            </CustomLink>
            </MobileMenu>
        </div>
    </>
);

const Navbar = props => {
    if (!props.data) {
        return null;
    }
    const data = props.data.edges[0].node.frontmatter;
    return <NavbarTemplate data={data} />;
};

export { Navbar };
