import React from "react";
import Helmet from "react-helmet";
import "moment/locale/de";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import CookieConsent from "react-cookie-consent";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import "../styles";
import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";

const theme = createMuiTheme({
  palette: {
    secondary: { main: "#419d78" },
    primary: { main: "#2d3047" }
  }
});

const TemplateWrapper = ({
  footerData = null,
  navbarData = null,
  children
}) => (
  <div>
    <Helmet>
      <html lang="de" />
      <meta
        name="keywords"
        content="Kellerstöckl Buchen Urlaub Burgenland Ferienwohnung"
      />
      <meta
        name="google-site-verification"
        content="OHtiVz9rXR0GY6p2ejD4h-cYitBejYTq7affS4UG-4Q"
      />
      <meta
        name="application-name"
        content="das Weinstöckl - Urlaub bei Freunden"
      />
    </Helmet>
    <Navbar data={navbarData} />
    <ThemeProvider theme={theme}>
      <main id="page-wrap">{children}</main>
    </ThemeProvider>
    <Footer data={footerData} />
    <CookieConsent
      location="bottom"
      cookieName="das-weinstoeckl-cookie-consent"
      buttonText="Ich stimme zu"
      declineButtonText="Ich stimme nicht zu"
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      enableDeclineButton
      onDecline={() => window.gaOptout()}
    >
      <div
        style={{
          maxWidth: "800px",
          margin: "0 auto"
        }}
      >
        Diese Website verwendet Cookies – nähere Informationen dazu und zu Ihren
        Rechten als Benutzer finden Sie in unserer{" "}
        <Link
          to="/datenschutz"
          target="_blank"
          style={{
            cursor: "pointer",
            textDecoration: "underline"
          }}
        >
          Datenschutzerklärung
        </Link>
        . Klicken Sie auf "Ich stimme zu", um Cookies zu akzeptieren und direkt
        unsere Website besuchen zu können.
      </div>
    </CookieConsent>
  </div>
);

export const query = graphql`
  fragment LayoutFragment on Query {
    footerData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "footer" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            logoImage {
              image {
                childImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              imageAlt
              tagline
              country
              district
              street
            }
            socialLinks {
              image {
                childImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              imageAlt
              label
              linkURL
            }
            pageLinks {
              label
              linkURL
              linkType
            }
            footerLogos {
              image {
                publicURL
              }
              imageAlt
              linkURL
            }
          }
        }
      }
    }
    navbarData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "navbar" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            logo {
              image {
                publicURL
              }
              imageAlt
            }
            menuItems {
              label
              linkType
              linkURL
            }
          }
        }
      }
    }
  }
`;

export default TemplateWrapper;
