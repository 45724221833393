import React, { Component } from 'react';
import Img from 'gatsby-image/withIEPolyfill';

export default class CustomImage extends Component {
  render() {
    const { gatsbyImage, alt, src, otherProps } = this.props;

    if (gatsbyImage) {
      return <Img {...gatsbyImage} alt={alt} {...otherProps} />;
    } else {
      return <img src={src} alt={alt} {...otherProps} />;
    }
  }
}
